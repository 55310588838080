<template>
  <base-section
    id="powerful-features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="700"
    >
      <base-info-title
        align="center"
        dark
        v-bind="getTitle"
      />
    </v-responsive>

    <v-responsive
      class="mx-auto"
    >
      <v-container fluid>
        <v-row justify="space-around">
          <v-col
            v-for="card in getCards"
            :key="card.title"
            cols="12"
            md="5"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPowerfulFeatures',

    computed: {
      getTitle () {
        return [
          {
            title: this.$t('featureTitle'),
            text: this.$t('featureSubTitle'),
          },
        ]
      },

      getCards () {
        return [
          {
            title: this.$t('featureReason1Title'),
            icon: 'mdi-cloud',
            text: this.$t('featureReason1Content'),
          },
          {
            title: this.$t('featureReason2Title'),
            icon: 'mdi-cube',
            text: this.$t('featureReason2Content'),
          },
          {
            title: this.$t('featureReason3Title'),
            icon: 'mdi-clock',
            text: this.$t('featureReason3Content'),
          },
          {
            title: this.$t('featureReason4Title'),
            icon: 'mdi-layers-edit',
            text: this.$t('featureReason4Content'),
          },
          {
            title: this.$t('featureReason5Title'),
            icon: 'mdi-database-search',
            text: this.$t('featureReason5Content'),
          },
          {
            title: this.$t('featureReason6Title'),
            icon: 'mdi-database-search',
            text: this.$t('featureReason6Content'),
          },
          {
            title: this.$t('featureReason7Title'),
            icon: 'mdi-database-search',
            text: this.$t('featureReason7Content'),
          },
          {
            title: this.$t('featureReason8Title'),
            icon: 'mdi-help-circle-outline',
            text: this.$t('featureReason8Content'),
          },
          {
            // color: 'primary',
            // dark: true,
            title: this.$t('featureReason9Title'),
            // icon: 'mdi-pencil-box-outline',
            icon: 'mdi-pencil-box',
            text: this.$t('featureReason9Content'),
          },
          {
            title: this.$t('featureReason10Title'),
            icon: 'mdi-cog',
            text: this.$t('featureReason10Content'),
          },
          {
            title: this.$t('featureReason11Title'),
            icon: 'mdi-fountain-pen-tip',
            text: this.$t('featureReason11Content'),
          },
          {
            title: this.$t('featureReason12Title'),
            icon: 'mdi-image-size-select-actual',
            text: this.$t('featureReason12Content'),
          },
          {
            title: this.$t('featureReason13Title'),
            icon: 'mdi-help-circle-outline',
            text: this.$t('featureReason13Content'),
          },
          {
            title: this.$t('featureReason14Title'),
            icon: 'mdi-help-circle-outline',
            text: this.$t('featureReason14Content'),
          },
        ]
      },
    },
  }
</script>
